import {BigNumber} from "ethers"
import clsx from "clsx"
import nearIcon from "../data/near_transparent.svg"
import {bigNumberToString} from "../utils/parse"

export default function AssetAmount({amount, className}) {
    if (amount instanceof BigNumber) {
        amount = bigNumberToString(amount)
    }

    return (
        <p className={clsx("flex gap-2 items-center", className)}>
            <span className="font-medium">{amount}</span>
            <img src={nearIcon} className="h-3 w-3 shape-auto" alt="Near Icon"/>
        </p>
    )

}