import {Fragment, useEffect, useState, useContext} from 'react'
import {Dialog, Transition} from '@headlessui/react'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faGear, faCircleCheck, faCircleXmark, faExclamationCircle} from "@fortawesome/pro-solid-svg-icons"
import clsx from "clsx"
import {BlockContext} from "./BlockHandler"
import {Button} from "./Button"
import AssetInput from "./AssetInput"

export default function Modal() {
    const [show, setShow] = useState(false)
    const [inputContent, setInputContent] = useState("")
    const {modalState, closeModalState} = useContext(BlockContext)

    useEffect(() => {
        // ignores first render of modal
        if (typeof modalState.close === 'undefined') return

        if (!show && !modalState.close) {
            setShow(true)
        } else if (show && modalState.close) {
            setShow(false)
        }

    }, [modalState, show, modalState?.close])

    const modalIcon = () => {
        if (modalState.type_ === 'loading') {
            return <FontAwesomeIcon icon={faGear} className="text-indigo-600 text-2xl animate-spin"/>
        } else if (modalState.type_ === 'failure') {
            return <FontAwesomeIcon icon={faCircleXmark} className="text-red-500"/>
        } else if (modalState.type_ === 'success') {
            return <FontAwesomeIcon icon={faCircleCheck} className="text-green-500"/>
        } else if (modalState.type_ && modalState.type_.startsWith('input')) {
        } else {
            return <FontAwesomeIcon icon={faExclamationCircle} className="text-amber-500"/>
        }
    }

    const modalButton = () => {
        if (modalState.type_ === 'success') {
            return (
                <div className="mt-4">
                    <button
                        type="button"
                        className="inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
                        onClick={closeModalState}
                    >
                        Go back to dashboard
                    </button>
                </div>
            )
        } else if (modalState.type_ === 'failure') {
            return (
                <div className="mt-4">
                    <button
                        type="button"
                        className="inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
                        onClick={closeModalState}
                    >
                        Go Back
                    </button>
                </div>
            )
        } else if (modalState.type_ === 'input-string') {
            return (
                <div className="flex flex-col gap-2 w-60 mx-auto mt-4">
                    <input
                        type="text"
                        name="project-name"
                        id="project-name"
                        value={inputContent}
                        onChange={(e) => setInputContent(e.target.value)}
                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        placeholder={modalState.placeholder}
                    />

                    <Button disabled={!inputContent}
                            onClick={() => modalState.callback(inputContent)}
                            className="w-full justify-center">
                        Save Project
                    </Button>
                </div>
            )
        } else if (modalState.type_ === 'input-number') {
            return (
                <div className="flex flex-col gap-2 w-60 mx-auto mt-4">
                    <AssetInput onChange={setInputContent}/>

                    <Button onClick={() => modalState.callback(inputContent)}
                            className="w-full justify-center">
                        {modalState.btnContent}
                    </Button>
                </div>
            )
        } else if (modalState.type_ === 'input-prompt') {
            return (
                <div className="flex flex-col gap-2 w-60 mx-auto mt-4">
                    <Button onClick={modalState.callback}
                            className="w-full justify-center">
                        {modalState.btnContent}
                    </Button>
                </div>
            )
        }
    }

    return (
        <Transition.Root show={show} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={() => {
            }}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel
                                className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                                <Dialog.Title as="h3" className={clsx("flex gap-2 justify-center items-center text-lg text-center font-medium leading-6 text-gray-900", modalState.type_ === 'loading' && "flex-col")}>
                                    <span>{modalIcon()}</span>
                                    <span>{modalState?.title}</span>
                                </Dialog.Title>
                                <div className="text-center">
                                    {modalState.description && <div className="mt-2">
                                        <p className="text-sm text-gray-500">
                                            {modalState.description}
                                        </p>
                                    </div>}
                                </div>
                                {modalButton()}
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}
