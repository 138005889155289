import clsx from "clsx"

const baseClasses = "inline-flex items-center rounded-full px-3 py-0.5 text-sm font-medium shadow-lg"

const categories = {
    "technology": {
        value: "technology",
        label: "Technology",
        badge: <span key="tech" className={clsx(baseClasses, "bg-yellow-100 text-yellow-800")}>Technology</span>
    },
    "web3": {
        value: "web3",
        label: "Web3",
        badge: <span key="web3" className={clsx(baseClasses, "bg-green-100 text-green-800")}>Web3</span>
    },
    "board-game": {
        value: "board-game",
        label: "Board Game",
        badge: <span key="boga" className={clsx(baseClasses, "bg-blue-100 text-blue-800")}>Board Game</span>
    },
    "game": {
        value: "game",
        label: "Game",
        badge: <span key="game" className={clsx(baseClasses, "bg-indigo-100 text-indigo-800")}>Game</span>
    },
    "creative": {
        value: "creative",
        label: "Creative",
        badge: <span key="create" className={clsx(baseClasses, "bg-purple-100 text-purple-800")}>Creative</span>
    },
    "environment": {
        value: "environment",
        label: "Environment",
        badge: <span key="envi" className={clsx(baseClasses, "bg-pink-100 text-pink-800")}>Environment</span>
    },
    "movie": {
        value: "movie",
        label: "Movie",
        badge: <span key="movie" className={clsx(baseClasses, "bg-rose-100 text-rose-800")}>Movie</span>
    },
    "music": {
        value: "music",
        label: "Music",
        badge: <span key="music" className={clsx(baseClasses, "bg-teal-100 text-teal-800")}>Music</span>
    },
    "art": {
        value: "education",
        label: "Education",
        badge: <span key="edu" className={clsx(baseClasses, "bg-amber-100 text-amber-800")}>Art</span>
    },
    "food": {
        value: "food",
        label: "Food",
        badge: <span key="food" className={clsx(baseClasses, "bg-lime-100 text-lime-800")}>Food</span>
    },
    "app": {
        value: "app",
        label: "App",
        badge: <span key="app" className={clsx(baseClasses, "bg-gray-100 text-gray-800")}>App</span>
    },
    "fundraiser": {
        value: "fundraiser",
        label: "Fundraiser",
        badge: <span key="fund" className={clsx(baseClasses, "bg-emerald-100 text-emerald-800")}>Fundraiser</span>
    }
}

export default categories