import {NavLink} from "react-router-dom"
import clsx from "clsx"

const baseClasses = "h-10 inline-flex justify-center items-center rounded-md border border-transparent px-4 py-2 text-sm text-center font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 hover:cursor-pointer"
const primaryClasses = "bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500"
const secondaryClasses = "bg-teal-600 hover:bg-teal-700 focus:ring-teal-500"
const dangerClasses = "bg-rose-600 hover:bg-rose-700 focus:ring-rose-500"
const disabledClasses = "disabled:bg-slate-300 disabled:text-black disabled:opacity-50"
const manualDisabledClasses = "bg-slate-300 text-black opacity-50 pointer-events-none cursor-not-allowed"

const Button = ({className, onClick, disabled, children, secondary=false, danger=false}) => {
    const colors = (danger) ? dangerClasses : (secondary) ? secondaryClasses : primaryClasses

    return (
        <button
            type="button"
            className={clsx(baseClasses, colors, disabledClasses, className)}
            onClick={onClick}
            disabled={Boolean(disabled)}
        >
            {children}
        </button>
    )
}

const Link = ({className, href, children, disabled, secondary=false, danger = false}) => {
    const colors = (danger) ? dangerClasses : (secondary) ? secondaryClasses : primaryClasses

    return (
        <a
            rel="noopener noreferrer" target="_blank"
            className={clsx(baseClasses, colors, className, disabled && manualDisabledClasses)}
            href={href}
        >
            {children || href}
        </a>
    )
}


const ButtonLink = ({className, to, children, alwaysActive, secondary, callback}) => {
    // mimic disabled selector behaviour from tailwind since anchors do not have that
    return (
        <NavLink className={({isActive}) => {
            return clsx((isActive && !alwaysActive) ? manualDisabledClasses : (secondary) ? secondaryClasses : primaryClasses, baseClasses, className)
        }} to={to} onClick={callback}>
            {children}
        </NavLink>
    )
}

export {Button, Link, ButtonLink}
