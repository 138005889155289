import {Fragment, useState} from "react"
import {Menu, Transition, Dialog, Disclosure} from "@headlessui/react"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faMagnifyingGlass, faChevronDown, faXmark} from "@fortawesome/pro-solid-svg-icons"
import clsx from "clsx"
import {CategorySelector, categoryFilter} from "./CategorySelector"


function sortBigNumber(projectA, projectB, attribute) {
    const [valueA, valueB] = [projectA[attribute], projectB[attribute]]

    if (valueA.lt(valueB)) {
        return -1
    } else if (valueB.lt(valueA)) {
        return 1
    } else {
        return 0
    }
}

const sortOptions = [
    {
        name: "Newest",
        sort: (a, b) => {
            return b.createdAt - a.createdAt
        }
    },
    {
        name: "Most Funders",
        sort: (a, b) => {
            return b.funders.length - a.funders.length
        }
    },
    {
        name: "Most Funding",
        sort: (a, b) => sortBigNumber(b, a, 'pledged')
    },
    {
        name: "Maximum Contribution",
        sort: (a, b) => sortBigNumber(b, a, 'maximumContribution')
    },
    {
        name: "HardCap",
        sort: (a, b) => sortBigNumber(b, a, 'hardCap')
    },
    {
        name: "Already Funded",
        sort: (a, b) => {
            return b.funded - a.funded
        }
    }
]

export default function Navigator(props) {
    const [open, setOpen] = useState(false)

    return (
        <div
            className="flex justify-between items-center rounded-lg bg-white border border-gray-300 divide-x lg:divide-x-0">
            <div className="w-full">
                <label htmlFor="search" className="sr-only">
                    Search
                </label>
                <div className="relative">
                    <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                        <FontAwesomeIcon icon={faMagnifyingGlass} className="text-sm lg:text-lg text-gray-400"/>
                    </div>
                    <input
                        name="search"
                        className="block w-full rounded-lg rounded-r-none border-none bg-gray-50 py-3 pl-10 pr-3 leading-5 placeholder-gray-500 focus:border-indigo-500 focus:placeholder-gray-400 focus:outline-none focus:ring-1 focus:ring-indigo-500 text-sm lg:text-base"
                        placeholder="Search by Project Name"
                        type="search"
                        value={props.query}
                        onChange={(e) => {
                            props.setQuery(e.target.value.toLowerCase())
                        }}
                    />
                </div>
            </div>

            <div className="h-full flex justify-between items-center divide-x">
                <button
                    type="button"
                    className="inline-block text-sm font-medium text-gray-700 hover:text-gray-900 mx-4 lg:mx-0 lg:hidden"
                    onClick={() => setOpen(true)}
                >
                    Filters
                </button>

                <div className="h-full hidden items-center px-4 lg:flex">
                    <input
                        name="Favorites"
                        defaultValue="false"
                        type="checkbox"
                        checked={props.favoritesOnly}
                        onChange={e => props.setFavoritesOnly(e.target.checked)}
                        className="h-4 w-4 rounded border-gray-400 text-indigo-600 focus:ring-indigo-500"
                    />
                    <label className="ml-3 text-sm text-gray-700">
                        Favorites
                    </label>
                </div>

                <div className="h-full hidden items-center px-4 lg:flex">
                    <CategorySelector selectedCategories={props.selectedCategories}
                                      setSelectedCategories={props.setSelectedCategories}/>
                </div>

                <div className="h-full hidden items-center px-4 lg:flex">
                    <Menu as="div" className="relative inline-block">
                        <Menu.Button
                            className="group inline-flex justify-center items-center text-sm text-gray-700 whitespace-nowrap hover:text-gray-900">
                            {props.order !== null ? props.order.name : "Order By"}
                            <FontAwesomeIcon icon={faChevronDown}
                                             className="-mr-1 ml-1 text-md flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                                             aria-hidden="true"
                            />
                        </Menu.Button>

                        <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                        >
                            <Menu.Items
                                className="absolute right-0 z-20 mt-2 w-48 origin-top-right rounded-md bg-white shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none">
                                <div className="py-1">
                                    {sortOptions.map((option) => (
                                        <Menu.Item onClick={() => {
                                            props.setOrder(option)
                                        }} key={option.name}>
                                            {({active}) => (
                                                <button
                                                    className={clsx("block w-full px-4 py-2 text-sm text-start hover:cursor-pointer",
                                                        (props.order === option) ? "font-medium text-gray-900" : "text-gray-500",
                                                        active && "bg-gray-100")}>
                                                    {option.name}
                                                </button>
                                            )}
                                        </Menu.Item>
                                    ))}
                                </div>
                            </Menu.Items>
                        </Transition>
                    </Menu>
                </div>
            </div>

            {/*Mobile Filter*/}
            <Transition.Root show={open} as={Fragment}>
                <Dialog as="div" className="relative z-40 lg:hidden" onClose={setOpen}>
                    <Transition.Child
                        as={Fragment}
                        enter="transition-opacity ease-linear duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="transition-opacity ease-linear duration-300"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black bg-opacity-25"/>
                    </Transition.Child>

                    <div className="fixed inset-0 z-40 flex">
                        <Transition.Child
                            as={Fragment}
                            enter="transition ease-in-out duration-300 transform"
                            enterFrom="translate-x-full"
                            enterTo="translate-x-0"
                            leave="transition ease-in-out duration-300 transform"
                            leaveFrom="translate-x-0"
                            leaveTo="translate-x-full"
                        >
                            <Dialog.Panel
                                className="relative ml-auto flex h-full w-full max-w-xs flex-col overflow-y-auto bg-white py-4 pb-6 shadow-xl">
                                <div className="flex items-center justify-between px-4">
                                    <h2 className="text-lg font-medium text-gray-900">Filters</h2>
                                    <button
                                        type="button"
                                        className="-mr-2 flex h-10 w-10 items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                                        onClick={() => setOpen(false)}
                                    >
                                        <span className="sr-only">Close menu</span>
                                        <FontAwesomeIcon icon={faXmark} className="text-2xl"/>
                                    </button>
                                </div>

                                <form className="mt-4">
                                    <div className="flex items-center px-4 py-6">
                                        <input
                                            name="Favorites"
                                            defaultValue="false"
                                            type="checkbox"
                                            checked={props.favoritesOnly}
                                            onChange={e => props.setFavoritesOnly(e.target.checked)}
                                            className="h-4 w-4 rounded border-gray-400 text-indigo-600 focus:ring-indigo-500"
                                        />
                                        <label className="ml-3 text-sm text-gray-700">
                                            Favorites
                                        </label>
                                    </div>

                                    <Disclosure as="div" className="border-t border-gray-200 px-4 py-6">
                                        {({open}) => (
                                            <Fragment>
                                                <h3 className="-mx-2 -my-3 flow-root">
                                                    <Disclosure.Button
                                                        className="flex w-full items-center justify-between bg-white px-2 py-3 text-sm text-gray-400">
                                                        <span className="font-medium text-gray-900">Category</span>
                                                        <span className="ml-6 flex items-center">
                            <FontAwesomeIcon icon={faChevronDown}
                                             className={clsx(open ? '-rotate-180' : 'rotate-0', 'text-xl transform')}
                                             aria-hidden="true"
                            />
                          </span>
                                                    </Disclosure.Button>
                                                </h3>
                                                <Disclosure.Panel className="pt-6 !z-20">
                                                    <div className="space-y-6">
                                                        {categoryFilter(props.selectedCategories, props.setSelectedCategories)}
                                                    </div>
                                                </Disclosure.Panel>
                                            </Fragment>
                                        )}
                                    </Disclosure>

                                    <Disclosure as="div" className="border-t border-gray-200 px-4 py-6">
                                        {({open}) => (
                                            <>
                                                <h3 className="-mx-2 -my-3 flow-root">
                                                    <Disclosure.Button
                                                        className="flex w-full items-center justify-between bg-white px-2 py-3 text-sm text-gray-400">
                                                        <span className="font-medium text-gray-900">
                                                            Order By
                                                        </span>
                                                        <span className="ml-6 flex items-center">
                                                            <FontAwesomeIcon icon={faChevronDown}
                                                                             className={clsx(open ? '-rotate-180' : 'rotate-0', 'text-xl transform')}
                                                                             aria-hidden="true"
                                                            />
                                                        </span>
                                                    </Disclosure.Button>
                                                </h3>
                                                <Disclosure.Panel className="pt-6">
                                                    {sortOptions.map((option) => (
                                                        <div onClick={(e) => {
                                                            e.preventDefault()
                                                            props.setOrder(option)
                                                        }} key={option.name}>
                                                            <button
                                                                className={clsx("block w-full px-4 py-2 text-sm text-start hover:cursor-pointer",
                                                                    (props.order === option) ? "font-medium text-gray-900" : "text-gray-500")}>
                                                                {option.name}
                                                            </button>
                                                        </div>
                                                    ))}
                                                </Disclosure.Panel>
                                            </>
                                        )}
                                    </Disclosure>
                                </form>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition.Root>
        </div>
    )
}