export default class Favorites {

    constructor() {
        this._favorites = JSON.parse(localStorage.getItem('favorites')) || []
    }

    includes(projectID) {
        return this._favorites.includes(projectID)
    }

    favor(projectID) {
        if (this._favorites.includes(projectID)) {
            this._favorites = this._favorites.filter((favorite) => {
                return favorite !== projectID
            })
        } else {
            this._favorites.push(projectID)
        }

        this.save()
        return this._favorites
    }

    save() {
        localStorage.setItem('favorites', JSON.stringify(this._favorites))
    }

    array() {
        return this._favorites
    }
}