//import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import sketch from "../data/sketch.png"
import {ButtonLink} from "./Button"

export default function Home() {
    return (
        <div className="mx-auto max-w-4xl">
            <h1 className="text-4xl font-bold tracking-tight text-center sm:text-6xl">
                Simple funding, open source and fair for everyone!
            </h1>
            <img src={sketch} alt="Crowdfunding Cartoon" className="w-11/12 max-w-2xl mx-auto mt-4"/>
            <p className="mt-6 text-lg leading-8 text-gray-600 text-center">
                OnlyFunds gives backers the power of DAO governance to control the release of funds raised and hold the
                founding team accountable.
            </p>

            <div className="mt-8 flex gap-x-4 justify-center">
                <ButtonLink to="explore" className="text-base h-auto">
                    Explore Projects
                </ButtonLink>

                <a href="https://docs.onlyfunds.finance" rel="noopener noreferrer" target="_blank"
                   className="inline-flex items-center rounded-md border border-gray-900/10 hover:border-gray-900/20
                   px-4 py-2 text-base font-medium shadow-sm text-gray-900
                   focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900/20"
                >
                    Read Docs
                </a>
            </div>
        </div>
    )
}
