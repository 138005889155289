import './App.css'
import {Fragment, useContext} from "react"
import {Routes, Route} from "react-router-dom"
import Modal from "./components/Modal"
import Header from "./components/Header"
import Home from "./components/Home"
import Explorer from "./components/Explorer"
import Creator from "./components/Creator"
import Manager from "./components/Manager"
import Funded from "./components/Funded"
import WrongPath from "./components/WrongPath"
import OverView from "./components/OverView"
import FundView from "./components/FundView"
import {BlockContext} from "./components/BlockHandler"
import Favorites from "./classes/Favorites"

function App() {
    const favorites = new Favorites()

    const {
        address,
        projects,
        personalProject,
        modalState,
        setModalState,
        isLoading,
        donate
    } = useContext(BlockContext)

    const creatorOrManager = () => {
        if (personalProject !== null) {
            return <Manager personalProject={personalProject}/>
        } else {
            return <Creator/>
        }
    }

    return (
        <Fragment>
            <Modal state={modalState} close={() => setModalState(null)}/>
            <Header owner={personalProject !== null}/>
            <div className="flex justify-center items-center mt-20 px-4 py-4 flex-1 lg:py-8">
                <Routes>
                    <Route path="/" element={<Home/>}/>
                    <Route path="explore" element={
                        <Explorer projects={projects}
                                  address={address}
                                  isLoading={isLoading}
                                  favorites={favorites}/>
                    }/>
                    <Route path="explore/:projectID" element={
                        <OverView projects={projects}
                                  isLoading={isLoading}
                                  favorites={favorites}
                                  donate={donate}
                        />
                    }/>
                    <Route path="funded" element={<Funded favorites={favorites}/>}/>
                    <Route path="funded/:projectID" element={
                        <FundView projects={projects}
                                  isLoading={isLoading}
                                  address={address}/>
                    }/>
                    <Route path="create" element={creatorOrManager()}/>
                    <Route path="*" element={<WrongPath/>}></Route>
                </Routes>
            </div>
        </Fragment>
    )
}

export default App
