import {useContext, useEffect, useState, Fragment} from "react"
import {BlockContext} from "./BlockHandler"
import {Button} from "./Button"
import Voting from "../classes/Voting"
import AssetAmount from "./AssetAmount"

import {BigNumber, ethers} from "ethers"

export default function VotingDisplay({project, children, ownerVotes = false}) {
    const {readChain, initiateVoting} = useContext(BlockContext)
    const [voting, setVoting] = useState({})

    useEffect(() => {
        (async () => {
            const totalSupply = await readChain(project, 'totalSupply')
            const voting = await readChain(project, 'votingData')
            setVoting(new Voting(voting, totalSupply))
        })()
    }, [project, readChain])

    const votingInformation = () => {
        if (voting.running) {
            const positivePercentage = voting.positiveVotes.mul(100).div(voting.maximumVotes)
            const negativePercentage = voting.negativeVotes.mul(100).div(voting.maximumVotes)

            return (
                <div className="w-full flex flex-col gap-2">
                    <span className="flex flex justify-between">
                        <p className="font-medium text-gray-500">Active Voting:</p>
                        <div className="h-6 flex gap-2 font-medium px-0.5 mb-0.5">
                            {(voting.type === 'cancel') ? "Cancel Project" : "Release"}
                            {voting.type === 'release' && <AssetAmount amount={BigNumber.from(0)}/>}
                        </div>
                    </span>

                    <div className="flex flex-col justify-center items-center text-sm">
                        <div
                            className="relative w-full flex justify-between h-2.5 bg-gray-200 rounded-full overflow-hidden">
                            <div className="absolute h-full left-1/2 border-r-4 border-gray-600"></div>
                            <div className="h-full bg-teal-600" style={{width: positivePercentage + "%"}}></div>
                            <div className="h-full bg-rose-600" style={{width: negativePercentage + "%"}}></div>
                        </div>
                    </div>
                </div>
            )
        }
    }

    const voteButtons = () => {
        if (voting.running) {
            return (
                <Fragment>
                    <Button className="flex-1" secondary={true}>Vote Yes</Button>
                    <Button className="flex-1" danger={true}>Vote No</Button>
                </Fragment>
            )
        } else {
            if (ownerVotes) {
                return (
                    <Fragment>
                        <Button secondary={true} className="w-full"
                                onClick={async () => await initiateVoting(project, 'startReleaseVoting', {inputPrompt: true})}>
                            Release Funds
                        </Button>
                        {children}
                    </Fragment>
                )
            } else {
                return <Button danger={true} className="w-full"
                               onClick={async () => await initiateVoting(project, 'startCancelVoting')}>
                    Initiate Cancel Vote
                </Button>
            }
        }
    }

    return (
        <div className="flex justify-center">
            <div className="w-full flex flex-col justify-between items-center gap-4">
                {votingInformation()}

                <div className="w-full flex flex-col justify-between gap-4 xs:flex-row">
                    {voteButtons()}
                </div>
            </div>
        </div>
    )

}