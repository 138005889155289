export default class Voting {

    constructor(data, maximumVotes) {
        this.running = data[0]
        this.type = (data[1] === 0) ? 'release' : 'cancel'
        this.totalVoters = data[2]
        this.positiveVotes = data[3]
        this.negativeVotes = data[4]
        this.totalVotes = this.positiveVotes.add(this.negativeVotes)
        this.maximumVotes = maximumVotes
        this.remainingTime = data[6]
    }
}