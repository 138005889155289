import {ethers} from "ethers";

function dZ(integerString) {
    return integerString > 9 ? integerString : "0" + integerString
}

function capitalize(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

function bigNumberToString(value) {
    const string = ethers.utils.formatEther(value)

    if (string.endsWith(".0")) {
        return string.slice(0, -2)
    } else {
        return string
    }
}

function convertUTC(date=new Date(), cleanHour=false) {
    if (cleanHour) {
        date.setHours(18, 0, 0)
    }
    // temporary best approach right now
    return new Date(date.toLocaleString("en-US", {timeZone: 'etc/UTC'}))
}

function dateToString(date) {
    const [day, month, year] = [dZ(date.getDate()), dZ(date.getMonth() + 1), dZ(date.getFullYear())]
    const [hour, minute] = [dZ(date.getHours()), dZ(date.getMinutes())]
    return `${day}/${month}/${year} at ${hour}:${minute}`
}

function remainingTime(targetDate, beginnerDate) {
    let secondDiff = (targetDate - beginnerDate) / 1000
    const daysOut = Math.floor(secondDiff / 86400)
    secondDiff -= daysOut * 86400

    const hoursOut = Math.floor(secondDiff / 3600)
    secondDiff -= hoursOut * 3600

    const minutesOut = Math.floor(secondDiff / 60)
    secondDiff -= minutesOut * 60

    return [daysOut, hoursOut, minutesOut, Math.floor(secondDiff)]
}

export {capitalize, convertUTC, bigNumberToString, dateToString, remainingTime}