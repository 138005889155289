import {initializeApp} from "firebase/app"
import {getFirestore} from "firebase/firestore"
import firebaseConfig from "../data/credentials.json"

const initiateFirestore = () => {
    const app = initializeApp(firebaseConfig)
    return getFirestore(app);
}

export {initiateFirestore}

