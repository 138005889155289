import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faStar as faStarSolid} from "@fortawesome/pro-solid-svg-icons"
import {faStar as faStarRegular} from "@fortawesome/pro-regular-svg-icons"
import clsx from "clsx"

export default function Favicon({state, callback, className}) {
    return (<button
            className={clsx("relative group inline-flex items-center rounded-md border border-gray-900/10 hover:border-gray-900/2 bg-gray-50 px-4 py-2 text-sm font-medium shadow-sm text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500 hover:bg-gray-100", className)}
            onClick={callback}>
            {state ? <FontAwesomeIcon icon={faStarSolid} className="text-amber-500 fa-fw"/> :
                <FontAwesomeIcon icon={faStarRegular} className="fa-fw"/>}
        </button>
    )
}