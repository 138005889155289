import {BigNumber, ethers} from "ethers"
import nearIcon from "../data/near.svg"
import clsx from "clsx"

export default function AssetInput({label, onChange, className, parentClassName}) {
    const parseInput = (e) => {
        try {
            return onChange(ethers.utils.parseEther(e.target.value))
        } catch (_) {
            return onChange(BigNumber.from(0))
        }
    }

    return (
        <div className={clsx("relative rounded-md shadow-sm", label && " mt-1", parentClassName)}>
            <input
                type="text"
                name="near-amount"
                className={clsx("block w-full h-full rounded-md bg-gray-50 border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm", className)}
                placeholder="0"
                onChange={parseInput}
            />
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                <img src={nearIcon} className="h-5 w-5 text-indigo-400" alt="Near Icon"/>
            </div>
        </div>
    )
}