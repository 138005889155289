import {Component, forwardRef} from "react"
import DatePicker from "react-datepicker"
import {format} from "date-fns"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faHouse, faChevronLeft, faChevronRight} from "@fortawesome/pro-solid-svg-icons"
import {faTwitter} from "@fortawesome/free-brands-svg-icons"
import {Button} from "./Button"
import {BlockContext} from "./BlockHandler"
import AssetInput from "./AssetInput"

// datepicker base: https://github.com/msnegurski/tailwind-react-datepicker
// TODO: make optional inputs

const ButtonInput = forwardRef(({value, onClick}, ref) => (
    <button
        onClick={onClick}
        ref={ref}
        type="button"
        className='inline-flex justify-start w-full px-3 py-2 text-sm font-medium text-gray-700 bg-gray-50
        border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none
        focus:ring-2 focus:ring-offset-0 focus:ring-indigo-500'
    >
        {format(new Date(value), 'dd MMMM yyyy')}
    </button>
))


const changeMonthClass = "inline-flex p-1 text-sm font-medium text-gray-700 bg-white border border-gray-300 " +
    "rounded shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-0 " +
    "focus:ring-blue-500 disabled:cursor-not-allowed disabled:opacity-50"


class Creator extends Component {
    static contextType = BlockContext

    baseState = {
        title: "",
        subtitle: "",
        description: "",
        startDate: new Date(),
        endDate: new Date(new Date().setMonth(new Date().getMonth() + 1)),
        softCap: "",
        hardCap: "",
        minimumContribution: "",
        maximumContribution: "",
        photo: null,
        websiteURL: "",
        twitterURL: "",
    }

    constructor(props) {
        super(props);
        this.state = this.baseState
    }

    componentDidUpdate(prevProps, prevState, _) {
        if (this.state.startDate > this.state.endDate) {
            if (prevState.startDate !== this.state.startDate) {
                this.setState({endDate: this.state.startDate})
            } else {
                this.setState({startDate: this.state.endDate})
            }
        }
    }

    handleSubmit = async () => {
        if (
            this.state.title === "" ||
            this.state.subtitle === "" ||
            this.state.description === "" ||
            this.state.startDate === null ||
            this.state.endDate === null ||
            this.state.softCap === null ||
            this.state.hardCap === null ||
            this.state.minimumContribution === null ||
            this.state.maximumContribution === null ||
            this.context.address === null
        ) {
            console.log("Invalid Input")
            // TODO: display input fields with bad values
            return
        }

        const response = await this.context.createProject(this.state)
        if (response === true) {
        } // TODO: Navigate to created overview
    }

    render() {
        return (
            <div className="flex flex-col items-end gap-2">
                <div className="rounded-lg bg-white shadow">
                    <form className="flex flex-col divide-y">
                        <div className="flex flex-col gap-2 px-4 py-5 sm:p-6">
                            <h3 className="text-lg font-medium leading-6 text-gray-900 text-indigo-600">
                                Project Information
                            </h3>

                            <div className="flex flex-col gap-2 mt-2">
                                <div className="sm:col-span-6">
                                    <label htmlFor="title" className="block text-sm font-medium text-gray-700">
                                        Title
                                    </label>
                                    <div className="mt-1 flex rounded-md shadow-sm">
                                        <input
                                            type="text"
                                            id="title"
                                            name="title"
                                            placeholder="Project Name"
                                            maxLength="32"
                                            value={this.state.title}
                                            onChange={(e) => {
                                                this.setState({title: e.target.value})
                                            }}
                                            className="block w-full min-w-0 flex-1 rounded-md bg-gray-50
                                            border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                        />
                                    </div>
                                </div>

                                <div className="sm:col-span-6">
                                    <label htmlFor="subtitle" className="block text-sm font-medium text-gray-700">
                                        Subtitle
                                    </label>
                                    <div className="mt-1 flex rounded-md shadow-sm">
                                        <input
                                            type="text"
                                            id="subtitle"
                                            name="subtitle"
                                            placeholder="Short description visible in the Explorer"
                                            maxLength="128"
                                            value={this.state.subtitle}
                                            onChange={(e) => {
                                                this.setState({subtitle: e.target.value})
                                            }}
                                            className="block w-full min-w-0 flex-1 rounded-md bg-gray-50
                                        border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                        />
                                    </div>
                                </div>

                                <div className="sm:col-span-6">
                                    <label htmlFor="about" className="block text-sm font-medium text-gray-700">
                                        Description
                                    </label>
                                    <div className="mt-1">
                                <textarea
                                    id="about"
                                    name="about"
                                    rows={7}
                                    placeholder="Write a few sentences about your project"
                                    maxLength="1024"
                                    value={this.state.description}
                                    onChange={(e) => {
                                        this.setState({description: e.target.value})
                                    }}
                                    className="block w-full rounded-md border-gray-300 shadow-sm bg-gray-50
                                    focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"/>
                                    </div>
                                </div>

                                <div className="sm:col-span-6">
                                    <label className="text-sm font-medium text-gray-900"
                                           htmlFor="file_input">Project Photo (3:2)</label>
                                    <input className="w-full text-sm text-gray-900 bg-gray-50 rounded-md
                                border border-gray-300 mt-1 hover:cursor-pointer file:text-white
                                file:border-indigo-600 file:border-none file:bg-indigo-600 file:px-4 file:py-2.5
                                file:rounded-l-sm focus:border-indigo-500 focus:ring-indigo-500
                                focus:ring-1 focus:outline-0 file:mr-4 file:font-sans" type="file"
                                           onChange={(e) => this.setState({photo: e.target.files[0]})}/>
                                </div>

                                <div className="sm:col-span-6">
                                    <label htmlFor="website-url" className="block text-sm font-medium text-gray-700">
                                        Website
                                    </label>
                                    <div className="relative mt-1 flex rounded-md shadow-sm">
                                        <input
                                            type="text"
                                            id="website-url"
                                            name="website-url"
                                            placeholder="Optional"
                                            value={this.state.websiteURL}
                                            onChange={(e) => {
                                                this.setState({websiteURL: e.target.value})
                                            }}
                                            className="block w-full min-w-0 flex-1 rounded-md bg-gray-50
                                        border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                        />
                                        <div
                                            className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                                            <FontAwesomeIcon icon={faHouse} className="text-lg text-gray-600"/>
                                        </div>
                                    </div>
                                </div>

                                <div className="sm:col-span-6">
                                    <label htmlFor="twitter-url" className="block text-sm font-medium text-gray-700">
                                        Twitter
                                    </label>
                                    <div className="relative mt-1 flex rounded-md shadow-sm">
                                        <input
                                            type="text"
                                            id="twitter-url"
                                            name="twitter-url"
                                            placeholder="Optional"
                                            value={this.state.twitterURL}
                                            onChange={(e) => {
                                                this.setState({twitterURL: e.target.value})
                                            }}
                                            className="block w-full min-w-0 flex-1 rounded-md bg-gray-50
                                        border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                        />
                                        <div
                                            className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                                            <FontAwesomeIcon icon={faTwitter} className="text-xl text-indigo-400"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="flex flex-col gap-2 px-4 py-5 sm:p-6">
                            <h3 className="text-lg font-medium leading-6 text-indigo-600">
                                Contribution requirements
                            </h3>

                            <div className="mt-2 grid grid-cols-1 gap-y-2 gap-x-4 sm:grid-cols-6">
                                <div className="sm:col-span-3">
                                    <label htmlFor="start-date" className="block text-sm font-medium text-gray-700">
                                        Start Date
                                    </label>

                                    <div className="relative mt-1">
                                        <DatePicker
                                            data-placement="bottom-start"
                                            selected={this.state.startDate}
                                            onChange={(date) => this.setState({startDate: date})}
                                            selectsStart
                                            startDate={this.state.startDate}
                                            endDate={this.state.endDate}
                                            nextMonthButtonLabel=">"
                                            previousMonthButtonLabel="<"
                                            popperClassName="react-datepicker-left !bottom-auto"
                                            customInput={<ButtonInput/>}
                                            renderCustomHeader={({
                                                                     date,
                                                                     decreaseMonth,
                                                                     increaseMonth,
                                                                     prevMonthButtonDisabled,
                                                                     nextMonthButtonDisabled,
                                                                 }) => (
                                                <div className="flex items-center justify-between px-2 py-2">
                                <span className="text-lg text-gray-700">
                                    {format(date, 'MMMM yyyy')}
                                </span>

                                                    <div className="space-x-2">
                                                        <button
                                                            onClick={decreaseMonth}
                                                            disabled={prevMonthButtonDisabled}
                                                            type="button"
                                                            className={changeMonthClass}
                                                        >
                                                            <FontAwesomeIcon icon={faChevronLeft}
                                                                             className="text-lg text-gray-600 px-1"/>
                                                        </button>

                                                        <button
                                                            onClick={increaseMonth}
                                                            disabled={nextMonthButtonDisabled}
                                                            type="button"
                                                            className={changeMonthClass}
                                                        >
                                                            <FontAwesomeIcon icon={faChevronRight}
                                                                             className="text-lg text-gray-600 px-1"/>
                                                        </button>
                                                    </div>
                                                </div>
                                            )}
                                        />
                                    </div>
                                </div>

                                <div className="sm:col-span-3">
                                    <label htmlFor="end-date" className="block text-sm font-medium text-gray-700">
                                        End Date
                                    </label>

                                    <div className="relative mt-1">
                                        <DatePicker
                                            selected={this.state.endDate}
                                            onChange={(date) => this.setState({endDate: date})}
                                            selectsEnd
                                            startDate={this.state.startDate}
                                            endDate={this.state.endDate}
                                            nextMonthButtonLabel=">"
                                            previousMonthButtonLabel="<"
                                            popperClassName="react-datepicker-right !bottom-auto"
                                            customInput={<ButtonInput/>}
                                            renderCustomHeader={({
                                                                     date,
                                                                     decreaseMonth,
                                                                     increaseMonth,
                                                                     prevMonthButtonDisabled,
                                                                     nextMonthButtonDisabled,
                                                                 }) => (
                                                <div className="flex items-center justify-between px-2 py-2">
                                <span className="text-lg text-gray-700">
                                    {format(date, 'MMMM yyyy')}
                                </span>

                                                    <div className="space-x-2">
                                                        <button
                                                            onClick={decreaseMonth}
                                                            disabled={prevMonthButtonDisabled}
                                                            type="button"
                                                            className={changeMonthClass}
                                                        >
                                                            <FontAwesomeIcon icon={faChevronLeft}
                                                                             className="text-lg text-gray-600 px-1"/>
                                                        </button>

                                                        <button
                                                            onClick={increaseMonth}
                                                            disabled={nextMonthButtonDisabled}
                                                            type="button"
                                                            className={changeMonthClass}
                                                        >
                                                            <FontAwesomeIcon icon={faChevronRight}
                                                                             className="text-lg text-gray-600 px-1"/>
                                                        </button>
                                                    </div>
                                                </div>
                                            )}
                                        />
                                    </div>
                                </div>

                                <div className="sm:col-span-3">
                                    <label htmlFor="soft-cap" className="block text-sm font-medium text-gray-700">
                                        Soft cap
                                    </label>
                                    <AssetInput label={true} onChange={(v) => this.setState({softCap: v})}/>
                                </div>

                                <div className="sm:col-span-3">
                                    <label htmlFor="hard-cap" className="block text-sm font-medium text-gray-700">
                                        Hard cap
                                    </label>
                                    <AssetInput label={true} onChange={(v) => this.setState({hardCap: v})}/>
                                </div>

                                <div className="sm:col-span-3">
                                    <label htmlFor="min-contribution"
                                           className="block text-sm font-medium text-gray-700">
                                        Minimum contribution
                                    </label>
                                    <AssetInput label={true}
                                                onChange={(v) => this.setState({minimumContribution: v})}/>
                                </div>

                                <div className="sm:col-span-3">
                                    <label htmlFor="max-contribution"
                                           className="block text-sm font-medium text-gray-700">
                                        Maximum contribution
                                    </label>
                                    <AssetInput label={true}
                                                onChange={(v) => this.setState({maximumContribution: v})}/>
                                </div>
                            </div>
                        </div>

                        <div className="flex justify-between gap-4 px-4 py-5 sm:p-6">
                            <Button onClick={() => this.setState(this.baseState)}
                                    secondary={true} className="w-24 justify-center">
                                Reset
                            </Button>
                            <Button disabled={this.context.address === null}
                                    onClick={this.handleSubmit} className="w-36 justify-center">
                                Create Project
                            </Button>
                        </div>
                    </form>
                </div>

                <div className="flex gap-1 text-sm pr-2">
                    <p>Already created a project?</p>
                    <button className="h-fit font-medium text-sm text-indigo-600"
                            onClick={this.context.checkForProject}>Check</button>
                </div>
            </div>
        )
    }
}

export default Creator