import clsx from "clsx"
import nearIcon from "../data/near_transparent.svg"
import {Fragment} from "react"

export default function ProgressBar({project, className, label = false}) {
    let overlayingClasses = "absolute h-2.5 bg-teal-600 rounded-full"
    let underlyingClasses = "h-2.5 bg-gray-300 rounded-full"

    if (project.percentageFulfilled >= project.percentageMinimum) {
        overlayingClasses += " initial"
        underlyingClasses += " absolute !bg-teal-700"
    }

    return (
        <Fragment>
            {label && <p className="flex justify-between font-medium text-gray-900">
                <span>Raised:</span>
                <span className="flex gap-2 items-center">
                                            <span>{project.display('pledged')}/{project.display('hardCap')}</span>
                                            <img src={nearIcon} className="h-4 w-4 shape-auto text-indigo-400"
                                                 alt="Near Icon"/>
                                        </span>
            </p>}

            <div className={clsx("relative w-full bg-gray-200 rounded-full h-2.5  ", className)}>
                <div className={overlayingClasses}
                     style={{width: project.percentageFulfilled + "%"}}/>
                <div className={underlyingClasses}
                     style={{width: project.percentageMinimum + "%"}}/>
            </div>
        </Fragment>
    )
}