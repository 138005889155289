import {ethers, BigNumber} from "ethers"
import {projectABI} from "../data/ABI"
import {dateToString, bigNumberToString, convertUTC} from "../utils/parse"
import categories from "../data/categories"

const placeholderURL = "https://firebasestorage.googleapis.com/v0/b/onlyfunds-4c513.appspot.com/o/assets%2Fplaceholder.png?alt=media&token=5846487a-b452-44cc-83d2-205d59650a19"

export default class Project {
    constructor(provider, id, data) {
        this.id = id
        this.owner = data.owner
        this.createdAt = (data.createdAt instanceof Date) ? data.createdAt : data.createdAt.toDate()
        this.title = data.title
        this.subtitle = data.subtitle
        this.description = data.description
        this.photoURL = data.photoURL || placeholderURL
        this.startDate = (data.startDate instanceof Date) ? data.startDate : data.startDate.toDate()
        this.endDate = (data.startDate instanceof Date) ? data.endDate : data.endDate.toDate()
        this.softCap = BigNumber.from(data.softCap)
        this.hardCap = BigNumber.from(data.hardCap)
        this.minimumContribution = BigNumber.from(data.minimumContribution)
        this.maximumContribution = BigNumber.from(data.maximumContribution)
        this.pledged = BigNumber.from(data.pledged)
        this.funders = data.funders
        this.websiteURL = data.websiteURL
        this.twitterURL = data.twitterURL
        this.contract = data.contract || new ethers.Contract(id, projectABI, provider)
        this.funded = data.funded
        this.cancelled = data.cancelled
        this.categories = Object.fromEntries(
            (data.categories || []).map((categoryID) => [categoryID, categories[categoryID]])
        )
    }

    static async fromChain(address, contractAddress, provider, projectTitle) {
        const contract = new ethers.Contract(contractAddress, projectABI, provider)
        const signer = contract.connect(provider.getSigner())
        const metaData = await signer.metaData()

        const [, , softCap, hardCap, minimumContribution, maximumContribution, startDate, endDate] = metaData

        return new Project(provider, contractAddress, {
            owner: address,
            createdAt: convertUTC(),
            title: projectTitle,
            subtitle: null,
            description: null,
            startDate: new Date(parseInt(startDate) * 1000),
            endDate: new Date(parseInt(endDate) * 1000),
            softCap: softCap,
            hardCap: hardCap,
            minimumContribution: minimumContribution,
            maximumContribution: maximumContribution,
            pledged: BigNumber.from(0),
            funders: [],
            contract: contract,
            funded: false,
            cancelled: false,
            categories: []
        })
    }

    get percentageMinimum() {
        return this.softCap.mul(BigNumber.from(100)).div(this.hardCap).toString()
    }

    get percentageFulfilled() {
        return this.pledged.mul(BigNumber.from(100)).div(this.hardCap).toString()
    }

    // TODO: limit decimals to readable amount (0.5555123123 -> 0.55)
    display(attribute) {
        if (this[attribute] instanceof BigNumber) {
            return bigNumberToString(this[attribute])

        } else if (this[attribute] instanceof Date) {
            return dateToString(this[attribute])
        } else {
            return "NaBN"
        }
    }

    fetchData = async (provider) => {
        const signer = this.contract.connect(provider.getSigner())
        return await signer.metaData()
    }

    fetchVotingData = async (provider) => {
        const signer = this.contract.connect(provider.getSigner())
        return await signer.votingData()
    }

    internalDonate = (address, amount) => {
        this.pledged = this.pledged.add(amount)
        if (!this.funders.includes(address)) {
            this.funders.push(address)
        }
    }
}