import {Popover, Transition} from "@headlessui/react"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faChevronDown} from "@fortawesome/pro-solid-svg-icons"
import {Fragment} from "react"
import categories from "../data/categories"

const categoryFilter = (selectedCategories, setSelectedCategories) => {
    return Object.values(categories).map((category, categoryID) => (
        <div key={category.value} className="flex items-center">
            <input
                id={`filter-category-${categoryID}`}
                name="category"
                defaultValue={category.value}
                checked={selectedCategories.includes(category.value)}
                type="checkbox"
                className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                onChange={(e) => setSelectedCategories(category.value, e.target.checked)}
            />
            <label
                htmlFor={`filter-category-${categoryID}`}
                className="ml-3 whitespace-nowrap pr-6 text-sm font-medium text-gray-900"
            >
                {category.label}
            </label>
        </div>
    ))
}

function CategorySelector({selectedCategories, setSelectedCategories}) {
    return (
        <Popover.Group className="hidden sm:flex sm:items-baseline sm:space-x-8">
            <Popover
                as="div"
                id="desktop-menu-category"
                className="relative inline-block text-left"
            >
                <div>
                    <Popover.Button
                        className="group inline-flex items-center justify-center text-sm font-medium text-gray-700 hover:text-gray-900">
                        <span>Category</span>
                        <span
                            className="ml-1.5 rounded bg-gray-200 py-0.5 px-1.5 text-xs font-semibold tabular-nums text-gray-700">
                                              {Object.values(selectedCategories).filter((e) => e).length}
                                            </span>
                        <FontAwesomeIcon icon={faChevronDown}
                                         className="-mr-1 ml-1 text-md flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                                         aria-hidden="true"
                        />
                    </Popover.Button>
                </div>

                <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                >
                    <Popover.Panel
                        className="absolute right-0 z-20 mt-2 origin-top-right rounded-md bg-white p-4 shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <form className="space-y-4">
                            {categoryFilter(selectedCategories, setSelectedCategories)}
                        </form>
                    </Popover.Panel>
                </Transition>
            </Popover>
        </Popover.Group>
    )
}

export {categoryFilter, CategorySelector}