import {useState, useContext, useEffect, useRef} from "react"
import isURL from "validator/lib/isURL"
import {Button} from "./Button"
import ProgressBar from "./Progress";
import nearIcon from "../data/near_transparent.svg";
import Countdown from "./Countdown";
import AssetAmount from "./AssetAmount";
import {BlockContext} from "./BlockHandler"
import VotingDisplay from "./VotingDisplay"
import {BigNumber} from "ethers"

const Input = ({placeholder, maxLength, rows, onChange, updateCallback, innerRef}) => {
    if (rows) {
        return (
            <textarea
                id="update"
                name="update"
                ref={innerRef}
                rows={rows}
                placeholder={placeholder}
                onChange={onChange}
                className="w-full min-w-0 flex-1 rounded-md bg-gray-50 border-gray-300
                focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                onKeyUp={(e) => {
                    if (e.key === 'Enter' && !e.shiftKey) {
                        updateCallback(e)
                    }
                }}
            />
        )
    } else {
        return (
            <input
                type="text"
                id="update"
                name="update"
                ref={innerRef}
                maxLength={maxLength}
                placeholder={placeholder}
                onChange={onChange}
                className="w-full min-w-0 flex-1 rounded-md bg-gray-50 border-gray-300
                focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                onKeyUp={(e) => {
                    if (e.key === 'Enter') {
                        updateCallback(e)
                    }
                }}
            />
        )
    }
}

export default function Manager({personalProject}) {
    const {updateProjectData, cancelProject, readChain} = useContext(BlockContext)
    const currentInput = useRef(null)

    const [fundsCollectable, setFundsCollectable] = useState(false)
    const [fundAmount, setFundAmount] = useState(BigNumber.from(0))

    const [updateState, setUpdateState] = useState({
        updateField: '',
        subtitle: personalProject.subtitle,
        description: personalProject.description,
        websiteURL: personalProject.websiteURL || "",
        twitterURL: personalProject.twitterURL || "",
        categories: [],
        photoURL: "",
        photo: null,
        changed: false
    })

    const resetValues = () => {
        setUpdateState({
            updateField: '',
            subtitle: personalProject.subtitle,
            description: personalProject.description,
            websiteURL: personalProject.websiteURL,
            twitterURL: personalProject.twitterURL,
            categories: [],
            photoURL: "",
            photo: null,
            changed: false
        })
    }

    const updateCallback = (updateKey) => {
        if (updateState.updateField === updateKey) {
            if (!currentInput.current.value) {
                setUpdateState({
                    ...updateState,
                    updateField: ""
                })
                return
            }

            if (updateKey.endsWith('URL')) {
                if (!isURL(currentInput.current.value, {require_protocol: true})) {
                    console.log("invalid")
                    return
                }
            }

            const updated = {...updateState}
            updated[updateState.updateField] = currentInput.current.value

            setUpdateState({
                ...updated,
                updateField: "",
                changed: true
            })
        } else {
            setUpdateState({
                ...updateState,
                updateField: updateKey
            })
        }
    }

    function Update({updateKey}) {
        return (
            <div className="flex justify-start p-4 pt-3.5 pb-3.5">
                <button className="h-fit font-medium text-sm text-indigo-600"
                        onClick={() => updateCallback(updateKey)}>
                    Update
                </button>
            </div>
        )
    }

    const temporaryFileHandle = (file) => {
        if (file.size > 10485760) {
            console.log("Filesize too big")
            return
        }

        const reader = new FileReader()

        reader.onload = (e) => {
            setUpdateState({
                ...updateState,
                updateField: "",
                photoURL: e.target.result,
                photo: file,
                changed: true
            })
        }

        reader.readAsDataURL(file)
    }

    useEffect(() => {
        (async () => {
            const collectable = await readChain(personalProject, 'partialFundsCollectable')
            const fundAmount = await readChain(personalProject, 'partialFundAmount')

            if (collectable) {
                setFundsCollectable(collectable)
                setFundAmount(fundAmount)
            }
        })()
    }, [personalProject, readChain])

    return (
        <div className="w-5xl flex flex-col gap-4 max-w-2xl lg:max-w-5xl lg:gap-6 lg:flex-row">
            <div className="flex flex-col justify-between items-center gap-4 lg:gap-6">
                <div className="w-full rounded-lg bg-white shadow lg:w-96 lg:h-64"
                     onClick={() => {
                         setUpdateState({
                             ...updateState,
                             updateField: 'photo'
                         })
                     }}>
                    {(updateState.updateField === 'photo') ?
                        (
                            <div
                                className="w-full h-64 flex justify-center items-center rounded-md border-2 border-dashed border-gray-300 p-6"
                                onDragOver={(e) => e.preventDefault()}
                                onDrop={(e) => {
                                    e.preventDefault()
                                    const imageType = /image.(png|jpg|jpeg)/;
                                    if (e.dataTransfer.files[0].type.match(imageType)) {
                                        temporaryFileHandle(e.dataTransfer.files[0])
                                    }
                                }}>
                                <div className="space-y-1 text-center">
                                    <svg
                                        className="mx-auto h-12 w-12 text-gray-400"
                                        stroke="currentColor"
                                        fill="none"
                                        viewBox="0 0 48 48"
                                        aria-hidden="true"
                                    >
                                        <path
                                            d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                            strokeWidth={2}
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                    </svg>
                                    <div className="flex text-sm text-gray-600">
                                        <label
                                            htmlFor="file-upload"
                                            className="relative cursor-pointer rounded-md bg-white font-medium text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:text-indigo-500"
                                        >
                                            <span>Upload a file</span>
                                            <input id="file-upload" name="file-upload" type="file" className="sr-only"
                                                   accept="image/png, image/jpeg"
                                                   onChange={(e) => temporaryFileHandle(e.target.files[0])}/>
                                        </label>
                                        <p className="pl-1">or drag and drop</p>
                                    </div>
                                    <p className="text-xs text-gray-500">PNG, JPG, GIF up to 10MB</p>
                                </div>
                            </div>
                        )

                        :
                        <img src={updateState.photoURL || personalProject.photoURL}
                             className="w-full object-cover object-center rounded-lg hover:cursor-pointer lg:w-96 lg:h-64"
                             alt="Project Icon"/>
                    }
                </div>

                <div
                    className="w-full h-full overflow-hidden rounded-lg bg-white flex flex-col divide-y shadow lg:w-96">
                    <div className="flex flex-col flex-1 justify-center items-center gap-2 p-4">
                        <h3 className="text-2xl font-medium leading-6 text-gray-900 text-center">
                            {personalProject.title}
                        </h3>
                        <Countdown project={personalProject}/>
                    </div>

                    <div className="flex flex-col justify-evenly gap-4 p-4">
                        <p className="flex justify-between text-md font-medium text-gray-900">
                            <span>From:</span>
                            <span>{`${personalProject.funders.length} Funders`}</span>
                        </p>

                        <p className="flex justify-between text-md font-medium text-gray-900">
                            <span>Raised:</span>
                            <span className="flex gap-2 items-center">
                                            <span>{personalProject.display('pledged')}/{personalProject.display('hardCap')}</span>
                                            <img src={nearIcon} className="h-4 w-4 shape-auto text-indigo-400"
                                                 alt="Near Icon"/>
                                        </span>
                        </p>
                        <ProgressBar project={personalProject}/>
                    </div>

                    <div className="flex flex-col justify-evenly gap-4 p-4">
                        <VotingDisplay project={personalProject} ownerVotes={true}>
                            <Button className="w-full max-w-lg lg:hidden" onClick={() => {
                                setUpdateState({
                                    ...updateState,
                                    updateField: (updateState.updateField === 'photo') ? '' : 'photo'
                                })
                            }}>Change Project Picture</Button>
                        </VotingDisplay>
                    </div>

                    <div className="flex flex-col justify-evenly gap-4 p-4">
                        <div className="font-medium text-center">
                            <p className="font-medium text-gray-500">No Funds collectable</p>
                        </div>
                    </div>

                    <div className="flex flex-col justify-between gap-4 p-4 xs:flex-row">
                        <Button danger={true} className="flex-1" onClick={() => cancelProject(personalProject)}>
                            Cancel Project
                        </Button>
                        <Button className="flex-1" disabled={!fundsCollectable || fundAmount.eq(0)}>
                            Collect Funds
                        </Button>
                    </div>
                </div>
            </div>

            <div className="min-w-min flex flex-col justify-between items-center flex-1 gap-4 lg:gap-6 lg:items-start">
                <div className="flex justify-between">
                    <Button className="w-fit hidden lg:block" onClick={() => {
                        setUpdateState({
                            ...updateState,
                            updateField: (updateState.updateField === 'photo') ? '' : 'photo'
                        })
                    }}>Change Project Picture</Button>

                    {/*<CategorySelector selectedCategories={updateState.categories}*/}
                    {/*                  setSelectedCategories={(key, value) => {*/}
                    {/*                      this.setState((prevState) => {*/}
                    {/*                          if (value) {*/}
                    {/*                              prevState.selectedCategories.push(key)*/}
                    {/*                          } else {*/}
                    {/*                              prevState.selectedCategories = prevState.selectedCategories.filter((categoryID) => {*/}
                    {/*                                  return categoryID !== key*/}
                    {/*                              })*/}
                    {/*                          }*/}
                    {/*                          return prevState*/}
                    {/*                      })*/}
                    {/*                  }}/>*/}
                </div>

                <div className="overflow-hidden w-full flex-1 bg-white shadow rounded-lg">
                    <dl className="h-full flex flex-col divide-y divide-gray-50">
                        <div className="flex flex-1 justify-between gap-4 bg-gray-50 p-4">
                            <div className="flex flex-1 flex-col justify-center text-sm font-medium">
                                <dt className="font-medium text-gray-500">Start Date</dt>
                                <dd className="text-gray-900">
                                    {personalProject.display('startDate')}
                                </dd>
                            </div>
                            <div className="flex flex-1 flex-col justify-center text-sm font-medium">
                                <dt className="text-gray-500">End Date</dt>
                                <dd className="text-gray-900">
                                    {personalProject.display('endDate')}
                                </dd>
                            </div>
                        </div>

                        <div className="flex flex-1 justify-between gap-4 p-4">
                            <div className="flex flex-1 flex-col justify-center text-sm font-medium">
                                <dt className="text-gray-500">Minimum Contribution</dt>
                                <dd className="text-gray-900">
                                    <AssetAmount amount={personalProject.display('minimumContribution')}/>
                                </dd>
                            </div>
                            <div className="flex flex-1 flex-col justify-center text-sm font-medium">
                                <dt className="text-gray-500">Maximum Contribution</dt>
                                <dd className="text-gray-900">
                                    <AssetAmount amount={personalProject.display('maximumContribution')}/>
                                </dd>
                            </div>
                        </div>

                        <div className="flex flex-1 justify-between gap-4 bg-gray-50 p-4">
                            <div className="flex flex-1 flex-col justify-center text-sm font-medium">
                                <dt className="text-gray-500">Soft Cap</dt>
                                <dd className="text-gray-900">
                                    <AssetAmount amount={personalProject.display('softCap')}/>
                                </dd>
                            </div>
                            <div className="flex flex-1 flex-col justify-center text-sm font-medium">
                                <dt className="text-gray-500">Hard Cap</dt>
                                <dd className="text-gray-900">
                                    <AssetAmount amount={personalProject.display('hardCap')}/>
                                </dd>
                            </div>
                        </div>
                    </dl>
                </div>

                <div className="overflow-hidden w-full bg-white shadow rounded-lg">
                    <dl className="h-full flex flex-col divide-y break-words">
                        <div className="flex flex-col lg:flex-row">
                            <div className="flex flex-col justify-center flex-1 p-4 pb-0 lg:pb-4">
                                <dt className="font-medium text-sm text-gray-500">Subtitle</dt>
                                <dd className="create-input text-sm text-gray-900 mt-2">
                                    {(updateState.updateField === 'subtitle') ?
                                        <Input innerRef={currentInput} placeholder={updateState.subtitle}
                                               maxLength="128"
                                               updateCallback={() => updateCallback('subtitle')}/>
                                        : updateState.subtitle}
                                </dd>
                            </div>
                            <Update updateKey="subtitle"/>
                        </div>

                        <div className="flex flex-col lg:flex-row">
                            <div className="flex flex-col justify-center flex-1 p-4 pb-0 lg:pb-4">
                                <dt className="font-medium text-sm text-gray-500">Description</dt>
                                <dd className="create-input text-sm text-gray-900 mt-2">
                                    {(updateState.updateField === 'description') ?
                                        <Input innerRef={currentInput} placeholder={updateState.description}
                                               maxLength="1024" rows={10}
                                               updateCallback={() => updateCallback('description')}/>
                                        : updateState.description}
                                </dd>
                            </div>
                            <Update updateKey="description"/>
                        </div>

                        <div className="flex flex-col lg:flex-row">
                            <div className="flex flex-col justify-center flex-1 text-sm p-4 pb-0 lg:pb-4">
                                <dt className="font-medium text-gray-500">Website</dt>
                                <dd className="create-input text-sm text-gray-900 mt-2">
                                    {(updateState.updateField === 'websiteURL') ?
                                        <Input innerRef={currentInput} placeholder={updateState.websiteURL}
                                               updateCallback={() => updateCallback('websiteURL')}/>
                                        : updateState.websiteURL || ""}
                                </dd>
                            </div>
                            <Update updateKey="websiteURL"/>
                        </div>

                        <div className="flex flex-col lg:flex-row">
                            <div className="flex flex-col justify-center flex-1 text-sm p-4 pb-0 lg:pb-4">
                                <dt className="font-medium text-gray-500">Twitter</dt>
                                <dd className="create-input text-sm text-gray-900 mt-2">
                                    {(updateState.updateField === 'twitterURL') ?
                                        <Input innerRef={currentInput} placeholder={updateState.twitterURL}
                                               updateCallback={() => updateCallback('twitterURL')}/>
                                        : updateState.twitterURL || ""}
                                </dd>
                            </div>
                            <Update updateKey="twitterURL"/>
                        </div>

                        <div className="flex flex-1 justify-between items-end p-4">
                            <Button danger={true} onClick={resetValues}
                                    disabled={!updateState.changed}>Reset</Button>
                            <Button onClick={async () => {
                                await updateProjectData(personalProject, {
                                    subtitle: updateState.subtitle,
                                    description: updateState.description,
                                    websiteURL: updateState.websiteURL,
                                    twitterURL: updateState.twitterURL,
                                    photo: updateState.photo
                                })
                                resetValues()
                            }} disabled={!updateState.changed}>Save</Button>
                        </div>
                    </dl>
                </div>
            </div>
        </div>
    )
}