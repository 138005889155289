import {useState, Fragment} from "react"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faHouse} from "@fortawesome/pro-solid-svg-icons"
import {faTwitter} from "@fortawesome/free-brands-svg-icons"
import {useParams} from "react-router-dom"
import {Button, Link} from "./Button"
import AssetInput from "./AssetInput"
import ProgressBar from "./Progress"
import Favicon from "./Favicon"
import nearIcon from "../data/near_transparent.svg"
import Countdown from "./Countdown"
import BaseView from "./BaseView"


export default function OverView({projects, favorites, isLoading, donate}) {
    const {projectID} = useParams()
    const project = projects[projectID]

    const [isFavorite, setIsFavorite] = useState(favorites.includes(projectID))
    const [amount, setAmount] = useState(null)

    return (
        <BaseView project={project} isLoading={isLoading} backwards="/explore">
            {project && <Fragment>
                <div className="flex flex-col flex-1 justify-center items-center gap-4 p-4">
                    <h3 className="text-2xl font-medium leading-6 text-gray-900 text-center">
                        {project.title}
                    </h3>
                    <Countdown project={project}/>
                </div>

                <div className="flex flex-col justify-evenly gap-2 p-4">
                    <p className="flex justify-between text-md font-medium text-gray-900">
                        <span>From:</span>
                        <span>{`${project.funders.length} Funders`}</span>
                    </p>

                    <p className="flex justify-between text-md font-medium text-gray-900">
                        <span>Raised:</span>
                        <span className="flex gap-2 items-center">
                                            <span>{project.display('pledged')}/{project.display('hardCap')}</span>
                                            <img src={nearIcon} className="h-4 w-4 shape-auto text-indigo-400"
                                                 alt="Near Icon"/>
                                        </span>
                    </p>
                </div>
                <div className="flex flex-col justify-evenly gap-4 p-4">
                    <ProgressBar project={project}/>

                    <div className="flex flex-col gap-4 xs:flex-row">
                        <AssetInput onChange={(v) => setAmount(v)} parentClassName="flex-1"/>

                        <Button disabled={amount ? amount.eq(0) : true}
                                onClick={async () => await donate(project, amount)}
                                className="w-full xs:w-36">
                            Donate
                        </Button>
                    </div>
                </div>

                <div className="flex justify-between gap-4 p-4">
                    <div className="flex gap-4">
                        <Link href={project.websiteURL} disabled={!project.websiteURL} secondary={true}>
                            <FontAwesomeIcon icon={faHouse} className="fa-fw"/>
                        </Link>

                        <Link href={project.twitterURL} disabled={!project.twitterURL}>
                            <FontAwesomeIcon icon={faTwitter} className="fa-fw"/>
                        </Link>
                    </div>

                    <Favicon state={isFavorite}
                             callback={() => {
                                 favorites.favor(projectID)
                                 setIsFavorite(favorites.includes(projectID))
                             }}/>
                </div>
            </Fragment>}
        </BaseView>
    )
}