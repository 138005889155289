import {Component} from "react"
import {remainingTime} from "../utils/parse";

export default class Countdown extends Component {
    constructor(props) {
        super(props);

        this.project = props.project
        const currentDate = new Date()
        this.fundingIncoming = this.project.startDate > currentDate
        this.fundingOngoing = !this.fundingIncoming && (this.project.endDate > currentDate)

        const [days, hours, minutes, seconds] = this.getTimes()

        this.state = {
            days: days,
            hours: hours,
            minutes: minutes,
            seconds: seconds
        }

        this.intervalID = null
    }

    getTimes = () => {
        const currentDate = new Date()
        const [endTime, startTime] = (this.fundingIncoming) ? [this.project.startDate, currentDate] : [this.project.endDate, currentDate]
        return remainingTime(endTime, startTime)
    }

    componentDidMount() {
        if (this.intervalID) return

        this.intervalID = setInterval(() => {
            const [days, hours, minutes, seconds] = this.getTimes()

            this.setState({
                days,
                hours,
                minutes,
                seconds
            })
        }, 1000)
    }

    componentWillUnmount() {
        if (this.intervalID) clearInterval(this.intervalID)
    }

    render() {
        if (!this.fundingIncoming && !this.fundingOngoing) {
            return <p className="font-medium">"Funding Period Over"</p>
        }

        return (
            <div className="flex flex-col justify-center items-center gap-3">
                <span
                    className="inline-flex items-center rounded-full bg-indigo-100 px-2.5 py-1 text-xs font-medium text-gray-800">
                    {this.fundingOngoing ? "ending in" : "starting in"}
                </span>

                <span className="countdown font-mono text-2xl">
                  <span style={{"--value": this.state.days}}>{this.state.days}</span>:
                  <span style={{"--value": this.state.hours}}>{this.state.hours}</span>:
                  <span style={{"--value": this.state.minutes}}>{this.state.minutes}</span>:
                  <span style={{"--value": this.state.seconds}}>{this.state.seconds}</span>
                </span>
            </div>
        )
    }

}