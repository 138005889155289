import {Fragment} from "react"
import {useParams} from "react-router-dom"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faEye, faFacePensive} from "@fortawesome/pro-solid-svg-icons"
import {ButtonLink} from "./Button"
import BaseView from "./BaseView"
import VotingDisplay from "./VotingDisplay"
import Countdown from "./Countdown"

export default function FundView({projects, address, isLoading}) {
    const {projectID} = useParams()
    const project = projects[projectID]

    if (!project || (project && project.funders.includes(address)))
        return (
            <BaseView project={project} isLoading={isLoading} backwards="/funded">
                {project &&
                    <Fragment>
                        <div className="flex flex-col flex-1 justify-center items-center gap-2 p-4">
                            <h3 className="text-2xl font-medium leading-6 text-gray-900 text-center">
                                {project.title}
                            </h3>
                            <Countdown project={project}/>
                        </div>
                        <div className="p-4">
                            <VotingDisplay project={project}/>
                        </div>
                    </Fragment>
                }
            </BaseView>
        )
    else {
        return (
            <div className="w-96 overflow-hidden rounded-lg bg-white shadow">
                <div className="text-center px-4 py-5 sm:p-6">
                    <FontAwesomeIcon icon={faFacePensive} className="text-3xl"/>
                    <h3 className="mt-2 text-sm font-medium text-gray-900">You did not fund this project</h3>
                    <div className="mt-4">
                        <ButtonLink to={`/explore/${projectID}`} alwaysActive={true}>
                            <FontAwesomeIcon icon={faEye} className="-ml-1 mr-2 text-xl"/>
                            View Project
                        </ButtonLink>
                    </div>
                </div>
            </div>
        )
    }
}