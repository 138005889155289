import {Component} from "react"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faHouse} from "@fortawesome/pro-solid-svg-icons"
import Navigator from "./Navigator"
import {Button, Link, ButtonLink} from "./Button"
import ProgressBar from "./Progress"
import Favicon from "./Favicon"
import nearIcon from "../data/near_transparent.svg"


// TODO Paginator and Project Limit
export default class Explorer extends Component {
    constructor(props) {
        super(props)

        this.state = {
            favorites: props.favorites.array(),
            favoritesOnly: false,
            query: "",
            selectedCategories: [],
            order: null
        }
    }

    sortedProjects = () => {
        const base = Object.values(this.props.projects).filter((project) => {
            if (this.state.favoritesOnly && !this.state.favorites.includes(project.id)) {
                return false
            } else if (this.state.query && !project.title.toLowerCase().includes(this.state.query)) {
                return false
            } else if (this.state.selectedCategories.length > 0) {
                if (!this.state.selectedCategories.some((categoryID) => {
                    return Boolean(project.categories[categoryID])
                })) {
                    return false
                }
            }

            return project
        })

        if (this.state.order !== null) {
            base.sort(this.state.order.sort)
        }

        return base
    }

    createSkeletons = (amount) => {
        return [...Array(amount).keys()].map((index) => {
            return (
                <div key={"skeleton_" + index} className="bg-gray-200 w-full h-card-height rounded-lg animate-pulse"
                     style={{height: 510}}>
                </div>
            )
        })
    }

    explorerContent = () => {
        if (this.props.isLoading) {
            return this.createSkeletons(3)
        }

        const projects = this.sortedProjects()

        if (projects.length > 0) {
            return projects.map(project => (
                <div key={project.id}
                     className="relative flex flex-col overflow-hidden rounded-lg border border-gray-200 bg-white">
                    <div className="absolute z-10 flex gap-4 p-4">
                        {Object.values(project.categories).map((category) => category.badge)}
                    </div>

                    <div className="relative aspect-w-3 aspect-h-2 bg-gray-200">
                        <img
                            src={project.photoURL} alt={project.title}
                            className="h-full w-full object-cover object-center sm:h-full sm:w-full"
                        />
                    </div>

                    <div className="flex flex-1 flex-col divide-y">
                        <div className="flex flex-col gap-2 flex-1 p-4">
                            <h3 className="text-gray-900 font-medium">
                                {project.title}
                            </h3>
                            <p className="text-gray-500 text-sm">
                                {project.subtitle}
                            </p>
                        </div>

                        <div className="flex flex-col gap-2 p-4 pt-3">
                            <p className="flex justify-between font-medium text-gray-900">
                                <span>Raised:</span>
                                <span className="flex gap-2 items-center">
                                            <span>{project.display('pledged')}/{project.display('hardCap')}</span>
                                            <img src={nearIcon} className="h-4 w-4 shape-auto text-indigo-400"
                                                 alt="Near Icon"/>
                                        </span>
                            </p>
                            <ProgressBar project={project}/>
                        </div>

                        <div className="flex justify-between gap-4 p-4">
                            <ButtonLink to={project.id} className="w-full justify-center gap-1">
                                <span>Open</span>
                                <span className="hidden xs:block">Project</span>
                            </ButtonLink>
                            <Link href={project.websiteURL} disabled={!project.websiteURL} secondary={true}>
                                <FontAwesomeIcon icon={faHouse} className="fa-fw"/>
                            </Link>
                            <Favicon state={this.state.favorites.includes(project.id)} callback={() => {
                                const newArray = this.props.favorites.favor(project.id)
                                this.setState({favorites: newArray})
                            }}/>
                        </div>
                    </div>
                </div>
            ))
        } else {
            return (
                <div className="flex justify-center col-span-full">
                    <div className="text-center rounded-lg border-2 border-dashed border-gray-300 px-4 py-8">
                        <h3 className="mt-2 text-sm font-medium text-gray-900">No projects found</h3>
                        <p className="mt-1 text-sm text-gray-500">Reset the filter or start your own project</p>
                        <div className="w-fit flex flex-col justify-between gap-4 mx-auto mt-6 xs:flex-row">
                            <Button danger={true} onClick={
                                () => this.setState({
                                    favoritesOnly: false,
                                    query: "",
                                    order: null
                                })
                            }>
                                Reset Filter
                            </Button>
                            <ButtonLink to="/create">
                                Create Project
                            </ButtonLink>
                        </div>
                    </div>
                </div>
            )
        }
    }

    render() {
        return (
            <div className="w-full self-stretch max-w-6xl flex flex-col gap-4 lg:gap-8">
                <Navigator
                    query={this.state.query} setQuery={(query) => this.setState({query})}
                    order={this.state.order} setOrder={(order) => this.setState({order})}
                    favoritesOnly={this.state.favoritesOnly}
                    setFavoritesOnly={favoritesOnly => this.setState({favoritesOnly})}
                    selectedCategories={this.state.selectedCategories}
                    setSelectedCategories={(key, value) => {
                        this.setState((prevState) => {
                            if (value) {
                                prevState.selectedCategories.push(key)
                            } else {
                                prevState.selectedCategories = prevState.selectedCategories.filter((categoryID) => {
                                    return categoryID !== key
                                })
                            }
                            return prevState
                        })
                    }}
                />
                <div
                    className="grid grid-cols-1 gap-y-4 sm:grid-cols-2 sm:gap-x-6 lg:grid-cols-3 lg:gap-8">
                    {this.explorerContent()}
                </div>
            </div>
        )
    }
}