import {Fragment} from "react"
import {Popover, Transition} from "@headlessui/react"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faXmark, faBars} from "@fortawesome/pro-solid-svg-icons"
import ConnectButton from "./Connect"
import {ButtonLink} from "./Button"
import icon from '../data/logo.png'

export default function Header({owner}) {
    return (
        <Popover
            className="fixed z-10 w-full h-20 flex justify-center bg-white border border-gray-300 px-4 sm:px-6 md:space-x-10">
            <div className="w-full max-w-screen-2xl flex items-center justify-between">
                <a href="./">
                    <span className="sr-only">OnlyFunds</span>
                    <img
                        className="h-8 w-auto"
                        src={icon}
                        alt="OnlyFunds Icon"
                    />
                </a>

                <div className="hidden gap-4 lg:flex">
                    <ButtonLink to="/">
                        Home
                    </ButtonLink>
                    <ButtonLink to="explore">
                        Explore
                    </ButtonLink>
                    <ButtonLink to="funded">
                        Funded Projects
                    </ButtonLink>
                    <ButtonLink to="create" className="w-36 justify-center">
                        {owner ? "Manage Project" : "Create Project"}
                    </ButtonLink>
                </div>

                <ConnectButton className="hidden lg:flex"/>

                <div className="lg:hidden">
                    <Popover.Button
                        className="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                        <span className="sr-only">Open menu</span>
                        <FontAwesomeIcon icon={faBars} className="text-2xl"/>
                    </Popover.Button>
                </div>

                <Transition
                    as={Fragment}
                    enter="duration-200 ease-out"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="duration-100 ease-in"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                >
                    <Popover.Panel focus
                                   className="absolute max-w-md w-full top-0 right-0 origin-top-right transform p-2 z-20 transition lg:hidden">
                        {({close}) => (
                            <div
                                className="divide-y-2 divide-gray-50 rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
                                <div className="px-5 pt-5 pb-6">
                                    <div className="flex items-center justify-end">
                                        <div className="-mr-2">
                                            <Popover.Button
                                                className="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                                                <span className="sr-only">Close menu</span>
                                                <FontAwesomeIcon icon={faXmark} className="text-2xl"/>
                                            </Popover.Button>
                                        </div>
                                    </div>
                                    <div className="gap-4 grid grid-cols-1 mt-6 xs:grid-cols-2">
                                        <ButtonLink to="/" secondary={true} callback={close}>
                                            Home
                                        </ButtonLink>
                                        <ButtonLink to="explore" secondary={true} callback={close}>
                                            Explore
                                        </ButtonLink>
                                        <ButtonLink to="funded" secondary={true} callback={close}>
                                            Funded Projects
                                        </ButtonLink>
                                        <ButtonLink to="create" className="lg:w-36" secondary={true} callback={close}>
                                            {owner ? "Manage Project" : "Create Project"}
                                        </ButtonLink>
                                    </div>
                                </div>
                                <div className="py-6 px-5">
                                    <div className="grid grid-cols-1 gap-4 xs:grid-cols-2">
                                        <ConnectButton className="!w-full"/>

                                        <a href="https://docs.onlyfunds.finance" rel="noopener noreferrer"
                                           target="_blank"
                                           className="inline-flex justify-center items-center rounded-md border
                                       border-gray-900/10 hover:border-gray-900/20 px-4 py-2 text-base font-medium
                                       shadow-sm text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2
                                       focus:ring-gray-900/20">
                                            Read Docs
                                        </a>
                                    </div>

                                </div>
                            </div>
                        )}
                    </Popover.Panel>
                </Transition>
            </div>
        </Popover>
    )
}
