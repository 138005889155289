import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faGear} from "@fortawesome/pro-solid-svg-icons"
import {ButtonLink} from "./Button"
import AssetAmount from "./AssetAmount"
import NotFound from "./NotFound"


export default function BaseView({project, children, backwards, isLoading}) {
    if (isLoading) {
        return (
            <div className="mb-20">
                <FontAwesomeIcon icon={faGear} className="text-indigo-600 text-4xl animate-spin"/>
            </div>
        )
    }

    if (project) {
        return (
            <div className="flex flex-1 flex-col justify-center items-start gap-4 max-w-2xl lg:max-w-5xl lg:items-end">
                <div className="w-full flex flex-col gap-4 lg:flex-row order-2 lg:order-1 lg:gap-6">
                    <div className="relative flex flex-col gap-4 lg:gap-6">
                        <div className="w-full rounded-lg bg-white shadow lg:w-96 lg:h-64">
                            <img src={project.photoURL} className="object-cover object-center rounded-lg "
                                 alt="Project Icon"/>
                        </div>

                        <div className="absolute z-10 flex gap-4 p-4">
                            {Object.values(project.categories).map((category) => category.badge)}
                        </div>

                        <div
                            className="h-full overflow-hidden rounded-lg bg-white flex flex-col divide-y shadow lg:w-96">
                            {children}
                        </div>
                    </div>

                    <div className="w-full bg-white shadow rounded-lg">
                        <dl className="h-full grid grid-cols-1 sm:grid-cols-2 divide-y"
                            style={{gridTemplateRows: "1fr"}}>
                            <div className="flex flex-col sm:col-span-2 p-4">
                                <dt className="font-medium text-lg text-gray-500">Project Information</dt>
                                <dd className="mt-2 text-sm text-gray-900">
                                    {project.description}
                                </dd>
                            </div>

                            <div className="flex flex-col justify-center text-sm font-medium p-4">
                                <dt className="font-medium text-gray-500">Start Date</dt>
                                <dd className="text-gray-900">
                                    {project.display('startDate')}
                                </dd>
                            </div>
                            <div className="flex flex-col justify-center text-sm font-medium p-4">
                                <dt className="text-gray-500">End Date</dt>
                                <dd className="text-gray-900">
                                    {project.display('endDate')}
                                </dd>
                            </div>
                            <div className="flex flex-col justify-center text-sm font-medium p-4">
                                <dt className="text-gray-500">Minimum Contribution</dt>
                                <dd className="text-gray-900">
                                    <AssetAmount amount={project.display('minimumContribution')}/>
                                </dd>
                            </div>
                            <div className="flex flex-col justify-center text-sm font-medium p-4">
                                <dt className="text-gray-500">Maximum Contribution</dt>
                                <dd className="text-gray-900">
                                    <AssetAmount amount={project.display('maximumContribution')}/>
                                </dd>
                            </div>
                            <div className="flex flex-col justify-center text-sm font-medium p-4">
                                <dt className="text-gray-500">Soft Cap</dt>
                                <dd className="text-gray-900">
                                    <AssetAmount amount={project.display('softCap')}/>
                                </dd>
                            </div>
                            <div className="flex flex-col justify-center text-sm font-medium p-4">
                                <dt className="text-gray-500">Hard Cap</dt>
                                <dd className="text-gray-900">
                                    <AssetAmount amount={project.display('hardCap')}/>
                                </dd>
                            </div>
                        </dl>
                    </div>
                </div>

                {backwards && <ButtonLink to={backwards} alwaysActive={true}
                                          className="flex justify-center border pr-4 p-2 bg-white shadow text-gray-900
                            hover:bg-gray-100 focus:ring-gray-600 order-1 lg:order-2">
                    Back
                </ButtonLink>}
            </div>
        )
    } else {
        return <NotFound/>
    }
}