import {useContext} from "react"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faEye, faFaceFrownSlight} from "@fortawesome/pro-solid-svg-icons"
import {BlockContext} from "./BlockHandler"
import Explorer from "./Explorer"
import {ButtonLink} from "./Button";

export default function Funded({favorites}) {
    const {projects, address, isLoading} = useContext(BlockContext)

    const fundedProjects = []

    for (const project of Object.values(projects)) {
        if (project.funders.includes(address)) {
            fundedProjects.push(project)
        }
    }

    if (fundedProjects.length > 0 || isLoading) {
        return <Explorer projects={fundedProjects} favorites={favorites} isLoading={isLoading}/>
    } else {
        return (
            <div className="w-96 overflow-hidden rounded-lg bg-white shadow">
                <div className="text-center">
                    <div className="px-4 py-5 sm:p-6">
                        <FontAwesomeIcon icon={faFaceFrownSlight} className="text-3xl"/>
                        <h3 className="mt-2 text-sm font-medium text-gray-900">You did not fund a project yet</h3>
                        <p className="mt-1 text-sm text-gray-500">Get started by funding your first one</p>
                        <div className="mt-4">
                            <ButtonLink to="/explore">
                                <FontAwesomeIcon icon={faEye} className="-ml-1 mr-2 text-xl"/>
                                Explore
                            </ButtonLink>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}