import {createContext, Component} from "react"
import {BigNumber, ethers} from 'ethers'
import {initiateFirestore} from "../utils/fireStore"
import {collection, doc, getDocs, setDoc, updateDoc, arrayUnion} from "firebase/firestore"
import {getStorage, ref, uploadBytes, getDownloadURL} from "firebase/storage"
import {getAuth, signInAnonymously} from "firebase/auth"
import {factoryABI} from "../data/ABI"
import Project from "../classes/Project"
import {capitalize, convertUTC} from "../utils/parse"

const BlockContext = createContext(null)

// TODO: wrong network error
class BlockProvider extends Component {
    constructor(props) {
        super(props)

        this.network = null
        this.isCorrectNetwork = true
        this.supportedNetworkID = 1313161555
        this.supportedNetworkData = {
            chainId: "0x4e454153",
            rpcUrls: ["https://testnet.aurora.dev/"],
            chainName: "Aurora Testnet",
            nativeCurrency: {
                name: "Ethereum",
                symbol: "ETH",
                decimals: 18
            },
            blockExplorerUrls: ["https://testnet.aurorascan.dev/"]
        }

        this.db = initiateFirestore()

        this.state = {
            isLoading: true,
            address: null,
            network: null,
            projects: {},
            personalProject: null,
            modalState: {},
        }

        const {ethereum} = window
        this.ethereum = ethereum

        if (this.ethereum) {
            this.provider = new ethers.providers.Web3Provider(ethereum)

            this.ethereum.on('accountsChanged', async (wallets) => {
                if (wallets.length === 0) {
                    this.setState({address: null})
                } else {
                    const address = ethers.utils.getAddress(wallets[0])
                    const personalProject = Object.values(this.state.projects).find((project) => {
                        return project.owner === address
                    }) || null

                    this.setState({
                        address,
                        personalProject
                    })
                }
            })

            this.ethereum.on('chainChanged', (_) => {
                window.location.reload()
            })

        } else {
            this.provider = new ethers.providers.JsonRpcProvider("https://testnet.aurora.dev/")
        }

        this.factoryAddress = "0xbcDa25733Fe98812cad87b5d1D078D6C0BEC9858"
        this.factory = new ethers.Contract(this.factoryAddress, factoryABI, this.provider);
    }

    setModalState = (type_, title, options = {}) => {
        this.setState({
            modalState: {
                close: false,
                type_,
                title,
                ...options
            }
        })
    }

    closeModalState = () => {
        this.setState((prevState) => {
            prevState.modalState.close = true
            return prevState
        })
    }

    waitingFor = (reason) => {
        this.setModalState('loading', `Waiting for ${capitalize(reason)}`, {
            description: "Please do not close the Window"
        })
    }

    async activeMetaMaskWallet() {
        const accounts = await this.provider.listAccounts()
        return (accounts.length > 0) ? accounts[0] : null
    }

    async componentDidMount() {
        let address = null
        let personalProject = null
        this.network = await this.provider.getNetwork()
        this.isCorrectNetwork = this.network.chainId === this.supportedNetworkID

        if (this.isCorrectNetwork) {
            address = await this.activeMetaMaskWallet()
        }

        try {
            const auth = getAuth();
            await signInAnonymously(auth)
        } catch (error) {
            console.log(error)
            return
        }

        const projectsRef = collection(this.db, "projects");
        const response = await getDocs(projectsRef)

        const projects = {}
        response.forEach((projectDoc) => {
            const projectData = projectDoc.data()
            const project = projects[projectDoc.id] = new Project(this.provider, projectDoc.id, projectData)

            if (project.owner === address) {
                personalProject = project
            }
        })

        this.setState({
            address: address,
            projects: projects,
            isLoading: false,
            personalProject: personalProject
        })

    }

    switchToNetwork = async () => {
        await this.ethereum.request({
            method: "wallet_addEthereumChain",
            params: [this.supportedNetworkData]
        })
    }

    connect = async () => {
        if (this.state.address === null) {
            await this.provider.send("eth_requestAccounts", [])
            const signer = this.provider.getSigner()
            const address = await signer.getAddress()

            this.setState({
                address: address
            })
        }
    }

    createProject = async (data) => {
        this.waitingFor('Approval')
        const signer = this.factory.connect(this.provider.getSigner())

        // TODO: kill minutes and seconds
        data.createdAt = convertUTC()
        data.startDate = convertUTC(data.startDate, true)
        data.endDate = convertUTC(data.endDate, true)

        const response = await this.callChain(signer, 'createProject', {
            args: [
                this.state.address,
                data.softCap,
                data.hardCap,
                data.minimumContribution,
                data.maximumContribution,
                Math.round(data.startDate.getTime() / 1000),
                Math.round(data.endDate.getTime() / 1000)
            ]
        })

        if (!response) return
        const projectAddress = await this.readChain(signer, 'getProject', [this.state.address])
        if (BigNumber.from(projectAddress).eq(0)) return

        const docData = {
            owner: this.state.address,
            title: data.title,
            subtitle: data.subtitle,
            description: data.description,
            startDate: data.startDate,
            endDate: data.endDate,
            softCap: data.softCap.toString(),
            hardCap: data.hardCap.toString(),
            minimumContribution: data.minimumContribution.toString(),
            maximumContribution: data.maximumContribution.toString(),
            websiteURL: data.websiteURL,
            twitterURL: data.twitterURL,
            createdAt: data.createdAt,
            funders: [],
            pledged: "0",
            categories: data.categories,
        }

        const newDoc = doc(this.db, "projects", projectAddress)
        await setDoc(newDoc, docData)
        const project = new Project(this.provider, projectAddress, docData)

        this.setState((prevState) => {
            prevState.projects[project.id] = project
            return prevState
        })

        try {
            await this.uploadPhoto(projectAddress, data.photo, newDoc)
            this.setModalState('success', "project was successfully created")
            return true
        } catch (error) {
            console.log(error)
            this.setModalState('warning', "image upload failed")
            return true
        }
    }

    uploadPhoto = async (projectID, file, updatedDoc = null) => {
        const storage = getStorage();
        const storageRef = ref(storage, 'images/' + projectID);

        try {
            const uploadResult = await uploadBytes(storageRef, file, {contentType: 'image/png'})
            const downloadURL = await getDownloadURL(uploadResult.ref)

            if (updatedDoc) {
                await updateDoc(updatedDoc, {
                    photoURL: downloadURL
                })
            } else {
                return downloadURL
            }

        } catch (error) {
            console.log(error)
        }
    }

    checkForProject = async () => {
        if (!this.state.address) return
        this.setModalState('loading', "Looking for your Project...")

        for (const project of Object.values(this.state.projects)) {
            if (project.owner === this.state.address) {
                this.setState({personalProject: project})
            }
        }

        if (this.state.personalProject !== null) {
            this.closeModalState()
            return
        }

        const signer = this.factory.connect(this.provider.getSigner())
        const projectAddress = await this.readChain(signer, 'getProject', [this.state.address])

        if (BigNumber.from(projectAddress).eq(0)) {
            this.setModalState('failure', "No Project found")
        } else {
            this.setModalState('input-string', "Project found!", {
                placeholder: "Please enter the project name",
                btnContent: "Save Project",
                callback: async (projectName) => {
                    this.setModalState('loading', "Initiating Project...")
                    const project = await Project.fromChain(this.state.address, projectAddress, this.provider, projectName)
                    const newDoc = doc(this.db, "projects", projectAddress)

                    await setDoc(newDoc, {
                        owner: this.state.address,
                        title: project.title,
                        subtitle: project.subtitle,
                        description: project.description,
                        startDate: project.startDate,
                        endDate: project.endDate,
                        softCap: project.softCap.toString(),
                        hardCap: project.hardCap.toString(),
                        minimumContribution: project.minimumContribution.toString(),
                        maximumContribution: project.maximumContribution.toString(),
                        createdAt: project.createdAt,
                        funders: project.funders,
                        pledged: project.pledged.toString(),
                        funded: project.funded,
                        cancelled: project.cancelled,
                        categories: []
                    })

                    this.setState((prevState) => {
                        prevState.projects[project.id] = project
                        prevState.personalProject = project
                        return prevState
                    })

                    this.setModalState('success', "Project saved", {
                        description: "Please add all lost information"
                    })
                }
            })
        }
    }

    donate = async (project, amount) => {
        const response = await this.callChain(project, 'donate', {value: amount})

        if (response) {
            this.setModalState('success', "You donated successfully")
            project.internalDonate(this.state.address, amount)
            await this.updateFundingData(project)
        }
    }

    updateFundingData = async (project) => {
        await updateDoc(doc(this.db, 'projects', project.id), {
            funders: arrayUnion(this.state.address),
            pledged: project.pledged.toString()
        })
    }

    updateProjectData = async (project, data) => {
        const updatedDoc = doc(this.db, 'projects', project.id)

        if (data.photo) {
            this.setModalState('loading', "Uploading Photo")
            data.photoURL = await this.uploadPhoto(project.id, data.photo)
            delete data.photo
        }

        await updateDoc(updatedDoc, data)

        for (const [key, value] of Object.entries(data)) {
            project[key] = value
        }

        // re renders all projects after project update
        this.setState({
            projects: this.state.projects
        })

        this.setModalState('success', "Project successfully updated!")
    }

    initiateVoting = async (project, method, options = {args: [], inputPrompt: false}) => {
        const callback = async (value) => {
            if (!value || (value instanceof BigNumber && value.eq(0))) {
                this.closeModalState()
                return
            }

            const response = await this.callChain(project, method, {args: [value]})

            if (response) {
                this.setModalState('success', "Voting successfully initiated!")
                return true
            }
        }

        if (!options.inputPrompt) {
            await callback()
            return
        }

        this.setModalState('input-number', "You want to release a part of funds?", {
                placeholder: "Please specify the amount",
                btnContent: "Release Funds",
                callback: callback
            }
        )
    }

    cancelProject = async (project) => {
        this.setModalState('input-prompt', "Do you really want to cancel your Project?", {
            btnContent: "I'm aware of my actions",
            callback: async () => {
                const response = await this.callChain(project, 'cancelProject')

                if (response) {
                    this.setModalState('success', "Project successfully cancelled!")
                    return true
                }
            }
        })
    }

    readChain = async (signerOrProject, method, args = []) => {
        if (signerOrProject instanceof Project) {
            signerOrProject = signerOrProject.contract.connect(this.provider.getSigner())
        }

        return await signerOrProject[method](...args)
    }

    callChain = async (signerOrProject, method, options = {args: [], value: null, suppress: false}) => {
        if (signerOrProject instanceof Project) {
            signerOrProject = signerOrProject.contract.connect(this.provider.getSigner())
        }

        const args = options.args || []
        const additionalValues = {}

        if (options.value) {
            additionalValues.value = options.value
        }

        console.log(signerOrProject)
        console.log(method)

        try {
            this.waitingFor('Approval')
            const response = await signerOrProject[method](...args, additionalValues)

            this.waitingFor('Confirmation')
            await response.wait(4)
            return true

        } catch (error) {
            console.log(error)

            if (!options.suppress) {
                let reason = "Unknown Error"

                if (error.reason) {
                    reason = error.reason
                } else if (error.message) {
                    const matches = error.message.match(/'(.*?)'/)
                    if (matches) {
                        reason = JSON.parse(matches[1]).value.data.message
                        reason = reason.replace("ONLYFUNDS: ", "")
                    }
                }

                this.setModalState('failure', "Transaction failed", {
                    description: `Reason: ${reason}`
                })
            }

            return false
        }
    }

    render() {
        return (
            <BlockContext.Provider value={{
                readChain: this.readChain,
                isLoading: this.state.isLoading,
                address: this.state.address,
                projects: this.state.projects,
                personalProject: this.state.personalProject,
                checkForProject: this.checkForProject,
                modalState: this.state.modalState,
                setModalState: this.setModalState,
                closeModalState: this.closeModalState,
                createProject: this.createProject,
                connect: this.connect,
                isCorrectNetwork: this.isCorrectNetwork,
                switchToNetwork: this.switchToNetwork,
                donate: this.donate,
                updateProjectData: this.updateProjectData,
                initiateVoting: this.initiateVoting,
                cancelProject: this.cancelProject
            }}>
                {this.props.children}
            </BlockContext.Provider>
        )
    }
}

export {
    BlockContext,
    BlockProvider
}