import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faEye, faFileCircleQuestion} from "@fortawesome/pro-solid-svg-icons"
import {ButtonLink} from "./Button"


export default function NotFound() {
    return (
        <div className="w-96 overflow-hidden rounded-lg bg-white shadow">
            <div className="text-center px-4 py-5 sm:p-6">
                <FontAwesomeIcon icon={faFileCircleQuestion} className="text-3xl"/>
                <h3 className="mt-2 text-sm font-medium text-gray-900">Invalid Address</h3>
                <div className="mt-4">
                    <ButtonLink to="/explore" alwaysActive={true}>
                        <FontAwesomeIcon icon={faEye} className="-ml-1 mr-2 text-xl"/>
                        Explore
                    </ButtonLink>
                </div>
            </div>
        </div>
    )
}