import {useContext} from "react"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faExclamationCircle} from "@fortawesome/pro-solid-svg-icons"
import {BlockContext} from "./BlockHandler"
import clsx from "clsx"

export default function Connect({className}) {
    const {address, connect, isCorrectNetwork, switchToNetwork} = useContext(BlockContext)

    const buttonContent = () => {
        if (address !== null) {
            return "0x..." + address.substring(address.length - 6, address.length)
        } else if (!isCorrectNetwork) {
            return (
                <>
                    <FontAwesomeIcon icon={faExclamationCircle} className="mr-2"/>
                    <span className="mb-0.5">Switch Network</span>
                </>
            )
        } else {
            return "Connect"
        }
    }

    const buttonClass = () => {
        if (isCorrectNetwork) {
            return "inline-flex items-center justify-center w-32 rounded-md border border-transparent " +
                "bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 " +
                "focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        } else {
            return "inline-flex items-center justify-center w-42 rounded-md border border-transparent " +
                "bg-rose-500 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-rose-600 " +
                "focus:outline-none focus:ring-2 focus:ring-rose-400 focus:ring-offset-2"
        }
    }

    return (
        <button
            type="button"
            className={clsx(buttonClass(), className)}
            onClick={isCorrectNetwork ? connect : switchToNetwork}
        >
            {buttonContent()}
        </button>
    )
}